import React, { Fragment, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import printJS from 'print-js';
import { AnimatePresence } from 'framer-motion';
import noop from 'lodash/noop';
import ModalBase from '../..';
import routes from '../../../../../setup/consts/routes';
import Button from '../../../../atoms/Button';
import ButtonLink from '../../../../atoms/Button/buttonLink';
import { ButtonMode } from '../../../../atoms/Button/enums';
import DeliveryTimeline from '../../../../molecules/DeliveryTimeline';
import OrderSummaryContactList from '../../../../molecules/OrderSummaryContactList';
import OrderSummaryTotals from '../../../../molecules/OrderSummaryTotals';
import ShippingLabelCard from '../../../../molecules/ShippingLabelCard';
import Table from '../../../../molecules/Table';
import { StyledBox } from '../../../../styles/Grid/styles';
import Typography from '../../../../styles/Typography';
import {
  getCustomerInfo,
  getShippingLabelSrc,
  getTableData,
  productsSummaryTableColumns,
} from './consts';
import {
  StyledFailedAttemptsList,
  StyledFailedAttemptsListContent,
  StyledFailedAttemptsListLabel,
  StyledOrderIdLabel,
  StyledOrderSummaryModalAction,
  StyledOrderSummaryModalContactInfoInner,
  StyledOrderSummaryModalContactInfoWrapper,
  StyledOrderSummaryModalTableExpandButton,
  StyledOrderSummaryModalTableExpandButtonWrapper,
  StyledOrderSummaryModalTableWrapper,
  StyledOrderSummaryModalTotalsWrapper,
  StyledOrderSummaryHeader,
} from './styles';
import { useCancelOrder, useOrderDetails } from './hooks';
import { OrderSummaryModalProps } from './types';
import Loader from '../../../../atoms/Loader';
import { LoaderMode } from '../../../../atoms/Loader/enums';
import useToggle from '../../../../../hooks/useToggle';
import ConfirmationModal from '../ConfirmationModal';
import { IconClose } from '../../../../../assets/svg/icons';
// eslint-disable-next-line import/no-cycle
import CustomerDetailsModal from '../CustomerDetailsModal';

const OrderSummaryModal: React.FC<OrderSummaryModalProps> = (props) => {
  const { onClose = noop, orderId, refetchOrders = noop } = props;
  const intl = useIntl();
  const [isConfirmModalOpen, { toggleOn, toggleOff }] = useToggle();
  const [customerSummaryModalIsOpen, toggleCustomerSummaryModal] = useToggle();

  const { order, loading } = useOrderDetails(orderId, onClose);
  const { cancelOrder, isLoading } = useCancelOrder(
    orderId,
    order?.fulfillmentId,
    () => {
      refetchOrders();
      onClose();
    }
  );

  const handleConfirmClick = () => {
    cancelOrder();
    toggleOff();
  };

  const data = useMemo(() => getTableData(order), [order]);
  const tableColumns = useMemo(() => productsSummaryTableColumns(intl), [intl]);

  const onClickCustomerId = () => {
    toggleCustomerSummaryModal.toggleOn();
  };

  const contactListItems = useMemo(
    () => getCustomerInfo(order, intl, onClickCustomerId),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, intl]
  );

  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const initialTableData = data.length > 3 ? data.slice(0, 3) : data;
  const restTableData = data.length > 3 ? data.slice(3) : [];

  return (
    <>
      <ModalBase {...{ ...props, onClose }} withCloseButton>
        <Container>
          {loading || isLoading || !order ? (
            <Loader mode={LoaderMode.OrderSummary} />
          ) : (
            <>
              <StyledOrderSummaryHeader>
                <Row>
                  <Col md={12}>
                    <StyledBox
                      alignItems='center'
                      justifyContent='space-between'
                      mb='2rem'
                    >
                      <StyledBox flexDirection='column'>
                        <Typography variant='h2' as='h2'>
                          <FormattedMessage
                            id='orders.orderSummary'
                            defaultMessage='Order Summary'
                          />
                        </Typography>
                        <StyledBox marginTop='0.25em'>
                          <StyledOrderIdLabel>
                            <FormattedMessage
                              id='orders.orderIdLabel'
                              defaultMessage='Order ID'
                            />
                          </StyledOrderIdLabel>
                          <Typography>{orderId}</Typography>
                        </StyledBox>
                        <StyledBox marginTop='0.25em'>
                          <StyledOrderIdLabel>
                            <FormattedMessage
                              id='orders.receiptNo'
                              defaultMessage='Receipt No'
                            />
                          </StyledOrderIdLabel>
                          <Typography>{order.externalReference}</Typography>
                        </StyledBox>
                      </StyledBox>
                      <StyledOrderSummaryModalAction>
                        <Button
                          mode={ButtonMode.Secondary}
                          disabled={order.isCancelled}
                          onClick={toggleOn}
                        >
                          <FormattedMessage
                            id='orders.cancelOrder'
                            defaultMessage='Cancel Order'
                          />
                        </Button>
                        <ButtonLink
                          mode={ButtonMode.Secondary}
                          disabled={order.isCancelled}
                          to={generatePath(routes.EDIT_ORDER, {
                            orderId,
                          })}
                        >
                          <FormattedMessage
                            id='orders.editOrder'
                            defaultMessage='Edit Order'
                          />
                        </ButtonLink>
                      </StyledOrderSummaryModalAction>
                    </StyledBox>
                  </Col>
                </Row>
              </StyledOrderSummaryHeader>
              <Row>
                <Col md={7}>
                  <StyledOrderSummaryModalTableWrapper>
                    {data.length > 0 ? (
                      <Table
                        disabledPagination
                        columns={tableColumns}
                        data={
                          !isTableExpanded
                            ? initialTableData
                            : [...initialTableData, ...restTableData]
                        }
                      />
                    ) : (
                      <Typography textAlign='center'>
                        <FormattedMessage
                          id='products.noProductsAdded'
                          defaultMessage='No products added'
                        />
                      </Typography>
                    )}
                    {data.length > 3 && !isTableExpanded && (
                      <StyledOrderSummaryModalTableExpandButtonWrapper>
                        <StyledOrderSummaryModalTableExpandButton
                          onClick={() => setIsTableExpanded(true)}
                        >
                          <FormattedMessage
                            id='orders.viewMoreProducts'
                            defaultMessage='View {productsCount} more products'
                            values={{ productsCount: data.length - 3 }}
                          />
                        </StyledOrderSummaryModalTableExpandButton>
                      </StyledOrderSummaryModalTableExpandButtonWrapper>
                    )}
                  </StyledOrderSummaryModalTableWrapper>
                  <StyledOrderSummaryModalTotalsWrapper>
                    <OrderSummaryTotals
                      total={order.totalPrice}
                      deliveryCharge={order.deliveryFee}
                      grandTotal={order.totalPrice + order.deliveryFee}
                      currency={order.currency}
                    />
                  </StyledOrderSummaryModalTotalsWrapper>
                  <StyledOrderSummaryModalContactInfoWrapper>
                    <StyledOrderSummaryModalContactInfoInner>
                      <OrderSummaryContactList items={contactListItems} />
                    </StyledOrderSummaryModalContactInfoInner>
                  </StyledOrderSummaryModalContactInfoWrapper>
                </Col>
                <Col md={5}>
                  <StyledBox mb='2.75rem'>
                    <ShippingLabelCard
                      title={intl.formatMessage({
                        id: 'orders.shippingLabel',
                        defaultMessage: 'Shipping Label',
                      })}
                      downloadLink={order.shippingLabelSrc}
                      downloadButtonLabel={intl.formatMessage({
                        id: 'forms.buttons.download',
                        defaultMessage: 'Download',
                      })}
                      printButtonLabel={intl.formatMessage({
                        id: 'forms.buttons.print',
                        defaultMessage: 'Print',
                      })}
                      onPrintClick={(link) =>
                        printJS(getShippingLabelSrc(link))
                      }
                      isCancelled={order.isCancelled}
                    />
                  </StyledBox>

                  {/* Failed Attempts */}
                  {order.failedAttempts?.length ? (
                    <StyledBox flexDirection='column' gap='0.5rem' mb='1.5rem'>
                      <Typography variant='bodyBold' as='h3'>
                        <FormattedMessage
                          id='failedAttempts'
                          defaultMessage='Failed Attempts'
                        />
                      </Typography>

                      {order.failedAttempts.map(
                        ({ attemptNumber, reason, reasonCode }) => (
                          <StyledFailedAttemptsList {...{ key: reasonCode }}>
                            {/* Attempt Number */}
                            <Typography>
                              {intl.formatMessage({ id: 'attemptNumber' })}:
                            </Typography>
                            <StyledFailedAttemptsListContent>
                              {attemptNumber}
                            </StyledFailedAttemptsListContent>

                            {/* Reason Code */}
                            <StyledFailedAttemptsListLabel>
                              {intl.formatMessage({ id: 'reasonCode' })}:
                            </StyledFailedAttemptsListLabel>
                            <StyledFailedAttemptsListContent>
                              {reasonCode}
                            </StyledFailedAttemptsListContent>

                            {/* Reason */}
                            <StyledFailedAttemptsListLabel>
                              {intl.formatMessage({ id: 'reason' })}:
                            </StyledFailedAttemptsListLabel>
                            <StyledFailedAttemptsListContent>
                              {reason}
                            </StyledFailedAttemptsListContent>
                          </StyledFailedAttemptsList>
                        )
                      )}
                    </StyledBox>
                  ) : null}

                  <DeliveryTimeline items={order.deliveryTimeline} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </ModalBase>

      <AnimatePresence>
        {isConfirmModalOpen && (
          <ConfirmationModal
            isWarning
            isPopup
            icon={IconClose}
            title={intl.formatMessage({
              id: 'orders.areYouSure',
              defaultMessage: 'Are you sure?',
            })}
            description={intl.formatMessage({
              id: 'orders.doYouWantToCancelThisOrder',
              defaultMessage:
                'Do you really want to cancel this order? This action cannot be undone',
            })}
            cancelButtonLabel={intl.formatMessage({
              id: 'forms.buttons.cancel',
              defaultMessage: 'Cancel',
            })}
            confirmButtonLabel={intl.formatMessage({
              id: 'forms.buttons.confirm',
              defaultMessage: 'Confirm',
            })}
            onConfirmButtonClick={handleConfirmClick}
            onClose={toggleOff}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {customerSummaryModalIsOpen && order?.customerId ? (
          <CustomerDetailsModal
            customerId={order.customerId}
            onClose={toggleCustomerSummaryModal.toggleOff}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default OrderSummaryModal;
