import format from 'date-fns/format';
import { defaultStaticRanges, StaticRange } from 'react-date-range';
import { IntlShape } from 'react-intl';
import { lastSevenDaysStaticRange, messages } from './consts';
import { SupportedStaticRange } from './enums';
import { RangeLabelKey } from './types';

export const formatDate = (date: Date): string => format(date, 'dd/MM/yyyy');

export const formatDateDashes = (date: Date): string =>
  format(date, 'dd-MM-yyyy');

export const getStaticRanges = (intl: IntlShape): StaticRange[] => {
  const lastWeekLabel = intl.formatMessage(
    messages[SupportedStaticRange.LastWeek]
  );
  const lastWeekRangeIndex = defaultStaticRanges.findIndex(
    (staticRange) =>
      intl.formatMessage(messages[staticRange.label as RangeLabelKey]) ===
      lastWeekLabel
  );

  const translatedDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: intl.formatMessage(messages[range.label as SupportedStaticRange]),
  }));

  return [
    ...translatedDefaultStaticRanges.slice(0, lastWeekRangeIndex + 1),
    {
      ...lastSevenDaysStaticRange,
      label: intl.formatMessage(messages[SupportedStaticRange.LastSevenDays]),
    },
    ...translatedDefaultStaticRanges.slice(lastWeekRangeIndex + 1),
  ];
};
